import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportService {

  private readMessageStatusSubject = new BehaviorSubject<boolean>(false);
  public readMessageStatus$ = this.readMessageStatusSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  // Tickets
  getTickets(queryParams: any): Observable<any> {
    return this.httpClient.get<any>(`ticket`, {
      params: this.getQueryParams(queryParams),
    });
  }

  getTicketDetails(ticketId: string): Observable<any> {
    return this.httpClient.get<any>(`ticket/ticketDetails/${ticketId}`);
  }

  addTicket(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/tickets `,
      payload
    );
  }

  clostTicket(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}ticket/closeTicket/${payload.ticketID}`,
      payload
    );
  }

  uploadTicketFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}ticket/uploadTicketFile`,
      payload
    );
  }

  sendTicketFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}ticket/ticketFile`,
      payload
    );
  }

  sendVendorTicketFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}ticket/sendFileInConversationToVendor`,
      payload
    );
  }

  ticketInvoiceRequestSend(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/invoiceRequest/${payload.ticketID} `,
      payload
    );
  }

  getInvoiceRequestTickets(payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/invoiceRequestList?${payload}`
    );
  }

  uploadInvoiceFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/invoice/uploadInvoiceFile  `,
      payload
    );
  }

  getTaxInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/invoice/taxInvoiceDetails/${payload.invoiceID}`
    );
  }

  getMessages(ticketId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}ticket/getMessages/${ticketId}`
    );
  }

  sendMessage(payload: any, ticketId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}ticket/sendAdminMessage/${ticketId}`,
      payload
    );
  }

  readMessages(ticketId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}ticket/readTicket/${ticketId}`
    );
  }

  getOrderReferenceList(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/orderReferenceList`,
      payload
    );
  }

  getOrderWiseItemReferenceList(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/itemReferenceList`,
      payload
    );
  }

  getTableFields(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/getTableFields?name=${payload.module}`
    );
  }

  // To update the status of readMessage completion
  updateReadMessageStatus(status: boolean) {
    this.readMessageStatusSubject.next(status);
  }

  private getQueryParams(queryParams?: Record<string, any>) {
    let httpQueryParams = new HttpParams();
    if (queryParams) {
      for (const queryParam in queryParams) {
        httpQueryParams = httpQueryParams.append(
          queryParam,
          queryParams[queryParam]
        );
      }
    }
    return httpQueryParams;
  }
}
