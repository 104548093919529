<div class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Ticket #{{ ticketDetails?.ticket_number }}</h4>
      <button type="button" class="close" (click)="closeModal()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="invRequestData">
        <div class="request-invoice-ticket ticket-chat-thread">
          <div class="head-info">
            <div class="personal-info">
              <h5 class="name mb-1" *ngIf="ticketDetails?.full_name">
                {{ ticketDetails?.full_name }}
              </h5>
              <ul>
                <li *ngIf="ticketDetails?.email">{{ ticketDetails?.email }}</li>
                <li *ngIf="ticketDetails?.contact?.internationalNumber">
                  {{ ticketDetails?.contact?.internationalNumber }}
                </li>
              </ul>
              <p class="service-name mt-2 mb-0" *ngIf="ticketDetails?.serviceName">
                <label class="font-weight-bold">Service:</label>
                <span> {{ ticketDetails?.serviceName }} </span>
              </p>
            </div>
            <div class="ordernumber" *ngIf="ticketDetails?.order_number">
              <a class="btn" [routerLink]="['/home/service/order-details']"
                [queryParams]="{ oid: ticketDetails?.orderId }" target="_blank">
                Order Number #{{ ticketDetails?.order_number }}
              </a>
            </div>

            <!-- <div class="product-details mt-2">
              <label class="font-weight-bold" *ngIf="ticketDetails?.productName">Service :</label>
              <span>
                {{ ticketDetails?.productName }}
              </span>
            </div> -->
            <!-- <div class="product-details mt-2">
              <label class="font-weight-bold" *ngIf="ticketDetails?.serviceName">Service:</label>
              <span> {{ ticketDetails?.serviceName }} </span>
              <label class="font-weight-bold" *ngIf="ticketDetails?.packageName">Package:</label>
              <span> {{ ticketDetails?.packageName }}</span>
            </div> -->
            <!-- <div class="product-details mt-2">
              <label class="font-weight-bold" *ngIf="ticketDetails?.packageName"
                >Package :</label
              >
              <span>
                {{ ticketDetails?.packageName }}
              </span>
            </div> -->
          </div>
          <div class="thread-block">
            <div class="date">
              {{ ticketDetails?.createdAt | date : 'dd/MM/yyyy' }}
            </div>
            <h4 class="title">{{ ticketDetails?.subject }}</h4>
            <p class="desc" [innerHtml]="ticketDetails?.description"></p>
            <div class="image-row">
              <div class="img-item" *ngFor="let i of ticketDetails?.ticketfiles">
                <img *ngIf="
                    i.img_url &&
                    !(
                      i.file_type === 'pdf' ||
                      i.file_type === 'doc' ||
                      i.file_type === 'docx' ||
                      i.file_type === 'mp4' ||
                      i.file_type === 'mov' ||
                      i.file_type === 'avi' ||
                      i.file_type === 'wvm' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'ogg' ||
                      i.file_type === 'mpeg' ||
                      i.file_type === '3gpp'
                    )
                  " src="{{ i.img_url }}" alt="ticket-img" />
                <!-- Link for pdf, doc, or docx files with icons -->
                <a *ngIf="
                    i.img_url &&
                    (i.file_type === 'pdf' ||
                      i.file_type === 'doc' ||
                      i.file_type === 'docx')
                  " [href]="i.img_url" target="_blank">
                  <img *ngIf="i.file_type === 'pdf'" src="assets/images/ticket-pdf-icon.svg" alt="pdf-icon" />
                  <img *ngIf="i.file_type === 'doc' || i.file_type === 'docx'" src="assets/images/ticket-doc-ic.png"
                    alt="doc-icon" />
                </a>
                <!-- Video file display -->

                <video *ngIf="
                    i.img_url &&
                    (i.file_type === 'mp4' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'avi' ||
                      i.file_type === 'wvm' ||
                      i.file_type === 'mov' ||
                      i.file_type === 'webm' ||
                      i.file_type === 'ogg' ||
                      i.file_type === 'mpeg' ||
                      i.file_type === '3gpp')
                  " [src]="i.img_url" controls width="100%" height="100%" alt="ticket-video"></video>
                <!-- src="assets/images/pin-attach-icon.svg" -->

                <!-- <a href="{{ i.img_url }}" target="_blank">
                  <img
                    *ngIf="i.img_url && i.file_type === 'pdf'"
                    src="assets/images/ticket-pdf-icon.svg"
                    alt="ticket-img"
                /></a> -->
                <!-- <span class="count">4</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="form-group chat-window-modal">
          <app-ticket-chat-configuration-modal (imgUploaded)="getTicketDetails()"
            [chatModalObject]="chatModalObject"></app-ticket-chat-configuration-modal>
        </div>
      </div>
    </div>
  </div>
</div>