import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { takeUntil } from 'rxjs';
import { CatalogManagementService } from 'src/app/core/services/catalog-management.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubscriptionDisposerComponent } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-cancellation-reasons-modal',
  templateUrl: './reject-reasons-modal.component.html',
})
export class RejectionReasonsModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('cancelModal') cancelModal: any;
  @Output() cancellationReason = new EventEmitter();

  reasonForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', Validators.required),
    customReason: new UntypedFormControl(''),
  });

  reasonList: any = [];
  isCustomReason: any = false;
  submitted = false;

  constructor(
    private catalogMngtService: CatalogManagementService,
    private generalService: GeneralService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCancelRequestReasons();
  }

  getCancelRequestReasons(): void {
    this.catalogMngtService
      .getReasons(2, 1)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response?.data && Array.isArray(response.data)) {
            this.reasonList = response.data.map((reason: any) => ({
              id: reason._id,
              text: reason.reason,
            }));
            this.reasonList?.push({
              id: 'custom',
              text: '+ Custom Reason',
            });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  save(): void {
    this.submitted = true; // Mark form as submitted

    if (this.reasonForm.invalid) {
      this.reasonForm.markAllAsTouched();
      return;
    } else if (!this.reasonForm.value.customReason && this.isCustomReason) {
      return;
    } else {
      const reasonToEmit = this.getReasonToEmit();
      if (reasonToEmit) {
        this.cancellationReason.emit(reasonToEmit);
        this.cancel();
      }

      this.cancellationReason?.emit(
        this.isCustomReason
          ? this.reasonForm?.value?.customReason
          : this.reasonForm?.value?.reason
      );
      this.cancel();
    }
  }

  getReasonToEmit(): string | null {
    if (this.isCustomReason) {
      return this.reasonForm.value.customReason || null;
    } else {
      const selectedReason = this.reasonList.find(
        (reason: any) => reason.id === this.reasonForm.value.reason
      );
      return selectedReason?.text || null;
    }
  }

  cancel(): void {
    this.resetForm();
    this.cancelModal?.nativeElement?.click();
  }

  resetForm(): void {
    this.reasonForm.reset();
    this.reasonForm.get('reason')?.setValue('');
    this.reasonForm.get('customReason')?.setValue('');
    this.isCustomReason = false;
    this.submitted = false;
  }

  get f() {
    return this.reasonForm.controls;
  }

  changeReason(data: any): void {
    // if (data == 'custom') {
    //   this.isCustomReason = true;
    // }
    if (data === 'custom') {
      this.isCustomReason = true;
      this.reasonForm.get('customReason')?.reset();
      this.reasonForm.get('customReason')?.setValidators(Validators.required);
      this.reasonForm.get('customReason')?.updateValueAndValidity();
    } else {
      this.isCustomReason = false;
      this.reasonForm.get('customReason')?.clearValidators();
      this.reasonForm.get('customReason')?.updateValueAndValidity();
    }

    this.submitted = false;
  }

  resetCustomReason(): void {
    this.reasonForm.patchValue({
      customReason: '',
      reason: '',
    });
    this.isCustomReason = false;
    // Remove validation when custom reason is reset
    this.reasonForm.get('customReason')?.reset();
    this.reasonForm.get('customReason')?.clearValidators();
    this.reasonForm.get('customReason')?.updateValueAndValidity();
    this.submitted = false;
  }
}
