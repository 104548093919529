import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ValidationService } from '@app/core/services';

import { AppSettingsService } from '@app/core/services/app-setting.service';
import { IAppSettings } from '@app/shared/model/common-model';
import { GeneralService } from '@core/services/general.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-package-configuration-modal',
  templateUrl: './package-configuration-modal.component.html',
})
export class PackageConfigurationModalComponent implements OnInit {
  @Output() editPackageOptionValuesData = new EventEmitter();
  @Output() isClosed = new EventEmitter();

  selectedPackageData: any;
  updatedPackageData: any;
  packageOptionValues?: any;
  packageOptionID?: any;
  defaultPackage: any;
  isReview = false;
  isNewPackage = false;
  reviewStatus: number;
  taxPercentage: number;
  categoryID: string;
  removeTaxPercentage = true;
  isSubmitted = false;
  isFormChanged = false;

  packageData = new UntypedFormGroup({
    title: new UntypedFormControl('', [ValidationService.required]),
    sale_price: new UntypedFormControl('', [ValidationService.required]),
    regular_price: new UntypedFormControl(''),
    sku: new UntypedFormControl(''),
    stock: new UntypedFormControl(1),
    description: new UntypedFormControl('', [ValidationService.required]),
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    cancellationHours: new UntypedFormControl('', [
      ValidationService.required,
      Validators.min(1),
    ]),
  });
  config!: AngularEditorConfig;
  appSettings: IAppSettings;
  totalAmount: number;
  isEligibleForCancellation: boolean = false;
  showTooltip = false;

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      selectedPackageData: any;
      updatedPackageData: any;
      packageOptionValues: any;
      packageOptionID: any;
      defaultPackage: any;
      isNewPackage: boolean;
      isReview: boolean;
      reviewStatus: number;
      categoryID: string;
    },
    public generalService: GeneralService,
    public appSettingsService: AppSettingsService
  ) {
    console.log('data');
    console.log(data);
    if (data?.selectedPackageData) {
      // this.selectedPackageData = data.selectedPackageData[0];
      this.selectedPackageData = JSON.parse(
        JSON.stringify(data.selectedPackageData)
      );
      // console.log('this.selectedPackageData');
      // console.log(this.selectedPackageData);
      // console.log('red');
      // console.log(this.selectedPackageData === this.updatedPackageData);
    }
    if (data?.updatedPackageData) {
      // this.updatedPackageData = data.updatedPackageData;
      this.updatedPackageData = JSON.parse(
        JSON.stringify(data.updatedPackageData)
      );
      // console.log('this.updatedPackageData');
      // console.log(this.updatedPackageData);
    }
    if (data?.packageOptionValues) {
      this.packageOptionValues = data.packageOptionValues;
    }
    if (data?.packageOptionID) {
      this.packageOptionID = data.packageOptionID;
    }
    if (data?.defaultPackage) {
      this.defaultPackage = data.defaultPackage;
    }
    if (data?.isReview) {
      this.isReview = data.isReview;
    }
    if (data?.isNewPackage) {
      this.isNewPackage = data.isNewPackage;
    }
    if (data?.reviewStatus) {
      this.reviewStatus = data.reviewStatus;
    }
    if (data?.categoryID) {
      this.categoryID = data.categoryID;
    }
  }

  get f() {
    return this.packageData.controls;
  }

  ngOnInit(): void {
    this.appSettings = this.appSettingsService.appSettings;
    this.setTaxercentageIfAvilable(this.categoryID);
    this.setPackageDetails();
    if (this.isReview) {
      this.packageData.disable();
    }
    this.config = this.generalService.getEditorConfig();
    if (!this.defaultPackage) {
      this.packageData.get('sku').setValidators([ValidationService.required]);
    } else {
      this.packageData.get('sku').clearValidators();
    }
    this.packageData.get('sku').updateValueAndValidity();

    this.packageData.valueChanges.subscribe(() => {
      this.isFormChanged = true;
    });
  }

  setPackageDetails() {
    // Existing initialization
    if (this.data?.selectedPackageData?.cancellationHours) {
      this.isEligibleForCancellation = true;
      this.packageData.controls['cancellationHours'].enable();
      this.packageData.patchValue({
        cancellationHours: this.data.selectedPackageData.cancellationHours,
      });
    }

    if (this.defaultPackage) {
      // console.log('this.defaultPackage');
      // console.log(this.defaultPackage);
      // console.log('name title');
      // console.log(
      //   this.selectedPackageData?.name + ' ' + this.selectedPackageData?.title
      // );
      this.packageData.patchValue({
        name: this.selectedPackageData?.name,
        title: this.selectedPackageData?.title,
        regular_price: this.selectedPackageData?.regular_price
          ? this.selectedPackageData?.regular_price
          : '',
        sku: this.selectedPackageData?.sku,
        description: this.selectedPackageData?.description,
        id: this.selectedPackageData?.id,
        sale_price: this.selectedPackageData?.sale_price,
        cancellationHours: this.selectedPackageData?.cancellation_time,
      });
      this.isEligibleForCancellation = this.selectedPackageData?.is_cancellable;
      this.calculateTotalAmount();
    } else {
      // console.log('in else');

      this.packageData.patchValue({
        name: this.packageOptionValues?.name,
        title: this.packageOptionValues?.name,
        regular_price: this.packageOptionValues?.price
          ? this.packageOptionValues?.price
          : '',
        sku: this.packageOptionValues?.sku,
        description: this.packageOptionValues?.description,
        id: this.packageOptionValues?.id,
        sale_price: this.packageOptionValues?.sale_price,
      });
      this.calculateTotalAmount();
    }

    if (this.updatedPackageData && this.reviewStatus == 3) {
      this.packageData.patchValue({
        name: this.updatedPackageData.name || this.selectedPackageData?.name,
        title: this.updatedPackageData.title || this.selectedPackageData?.name,
        regular_price:
          this.updatedPackageData.regular_price ??
          this.selectedPackageData?.price ??
          '',
        sku: this.updatedPackageData.sku ?? this.selectedPackageData?.sku,
        description:
          this.updatedPackageData.description ??
          this.selectedPackageData?.description,
        id: this.updatedPackageData.id ?? this.selectedPackageData?.id,
        sale_price:
          this.updatedPackageData.sale_price ??
          this.selectedPackageData?.sale_price,
        cancellationHours:
          this.updatedPackageData.cancellation_time ??
          this.selectedPackageData?.cancellation_time,
      });
      this.isEligibleForCancellation =
        this.updatedPackageData?.is_cancellable ??
        this.selectedPackageData?.is_cancellable;
    }

    if (this.reviewStatus === 2 || this.reviewStatus == 3) {
      this.packageData.disable();
    }
  }

  setTaxercentageIfAvilable(categoryId?: string) {
    this.taxPercentage = this.appSettings?.TAX_PERCENTAGE
      ? this.appSettings?.TAX_PERCENTAGE
      : 0;
    if (this.taxPercentage > 0 && categoryId !== null) {
      const taxExemptions: string[] = this.appSettings?.TAX_EXEMPTIONS;

      const targetIdToCheck: string = categoryId;

      const isIdPresent: boolean = taxExemptions.includes(targetIdToCheck);

      if (isIdPresent) {
        this.removeTaxPercentage = true;
        this.taxPercentage = 0;
      } else {
        this.removeTaxPercentage = false;
        this.taxPercentage = this.appSettings?.TAX_PERCENTAGE;
      }
    } else {
      this.taxPercentage = this.appSettings?.TAX_PERCENTAGE;
    }
  }
  checkValidHours(event: any) {
    let value = event.target.value;

    value = value.replace(/[^0-9]/g, '');

    event.target.value = value;
  }
  save(): void {
    this.isSubmitted = true;

    // Dynamically update form controls and validity
    if (!this.isEligibleForCancellation) {
      // If not eligible, disable and remove validators
      this.packageData.controls['cancellationHours'].clearValidators();
      this.packageData.controls['cancellationHours'].updateValueAndValidity();
    }

    if (this.packageData.invalid) {
      this.packageData.markAllAsTouched();
      return;
    } else {
      const data = this.packageData.value;
      const packageData = {
        ...data,
        regular_price: Number(this.packageData.value.regular_price),
        sale_price: Number(this.packageData.value.sale_price),
        is_cancellable: this.isEligibleForCancellation,
        cancellation_time: this.isEligibleForCancellation
          ? Number(this.packageData.value.cancellationHours)
          : 0,
      };
      const obj = {
        packageOptionID: this.packageOptionID,
        packageOptionValues: packageData,
        packageData: this.selectedPackageData,
      };
      this.close(obj);
    }
    this.isFormChanged = false;
  }

  checkValidPrice() {
    const regularPrice = Number(
      this.packageData.controls['regular_price'].value
    );
    const salePriceError = this.packageData.controls['sale_price']?.errors;
    const salePrice = Number(this.packageData.controls['sale_price'].value);
    if (regularPrice > 0 && regularPrice < salePrice) {
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
        salePriceInvalid: true,
      });
    } else {
      if (salePriceError) {
        delete salePriceError['salePriceInvalid'];
      }
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
      });
      this.calculateTotalAmount();
      this.packageData.controls['sale_price'].updateValueAndValidity();
    }
  }

  calculateTotalAmount() {
    const salePrice = this.packageData.get('sale_price').value;
    const taxRate = this.taxPercentage;
    const totalPrice = Number(salePrice) ? Number(salePrice) : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    this.totalAmount =
      totalAmount > 0 ? parseFloat(totalAmount.toFixed(2)) : 0.0;
  }

  maxAllowedTotal(regular_price) {
    return regular_price > 0
      ? parseInt(regular_price) +
          (this.taxPercentage / 100) * parseInt(regular_price)
      : undefined;
  }

  calculateSaleAmount() {
    let value = parseFloat(
      (this.totalAmount / (1 + this.taxPercentage / 100)).toFixed(2)
    );
    if (value) this.packageData.controls['sale_price'].setValue(value);
    else {
      this.packageData.controls['sale_price'].setValue('');
    }
    this.checkValidPrice();
  }

  reset(): void {
    this.packageData.reset();
    this.setPackageDetails();
    this.isFormChanged = false;
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  toggleCancellationEligibility(): void {
    if (this.isEligibleForCancellation) {
      // Enable and set validators for cancellationHours
      this.packageData.controls['cancellationHours'].setValidators([
        ValidationService.required,
        Validators.min(1),
      ]);
      this.packageData.controls['cancellationHours'].enable();
    } else {
      // Disable and clear validators for cancellationHours
      this.packageData.controls['cancellationHours'].clearValidators();
      this.packageData.controls['cancellationHours'].reset();
      this.packageData.controls['cancellationHours'].disable();
    }
    this.packageData.controls['cancellationHours'].updateValueAndValidity();
  }

  isModified(field: string): boolean {
    if (this.reviewStatus !== 3) return false;

    // if (!this.updatedPackageData) return false; // No updates
    // // return this.selectedPackageData[field] !== this.updatedPackageData[field];
    // const isFieldModified =
    //   this.selectedPackageData[field] !== this.updatedPackageData[field];

    // if (
    //   isFieldModified &&
    //   (field === 'sale_price' || field === 'regular_price')
    // ) {
    //   this.calculateTotalAmount();
    // }

    // return isFieldModified;

    // If new package, highlight all fields
    if (this.isNewPackage) {
      return true;
    }

    // If updatedPackageData is null and it's not a new package, No change
    if (!this.updatedPackageData) {
      return false;
    }

    // Highlight only modified fields
    const selectedValue = this.selectedPackageData?.[field] ?? '';
    const updatedValue = this.updatedPackageData?.[field] ?? '';

    const isFieldModified = selectedValue !== updatedValue;

    // If sale_price or regular_price is modified, recalculate total amount
    if (
      isFieldModified &&
      (field === 'sale_price' || field === 'regular_price')
    ) {
      this.calculateTotalAmount();
    }

    return isFieldModified;
  }
}
