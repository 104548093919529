<div class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ reviewStatus === 3 ? (updatedPackageData?.title || selectedPackageData?.title) : selectedPackageData?.title
        }}

      </h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">

      <form class="edit-form-row package-form-row" [formGroup]="packageData">
        <div class="form-group">
          <div class="left-title">
            <label> Package Title<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input appAlphaNumeric [allowSpecialCharacters]="'-'" type="text" class="form-control" placeholder="Title"
              maxlength="55" formControlName="title" [ngClass]="{'highlight-changes': isModified('title')}" />
            <div *ngIf="f['title'].errors && f['title'].errors['required']" class="required-note text-danger">
              Title is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="left-title">
            <label>Regular Price (Excluding VAT)</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input type="text" class="form-control" gfdNumbersOnly placeholder="Regular Price"
                formControlName="regular_price" (ngModelChange)="checkValidPrice()"
                [ngClass]="{'highlight-changes': isModified('regular_price')}" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Sale Price<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input type="text" class="form-control" gfdNumbersOnly [maxAllowedValue]="
                  packageData.controls['regular_price'].value > 0
                    ? packageData.controls['regular_price'].value
                    : undefined
                " placeholder="Sale Price" formControlName="sale_price" (blur)="checkValidPrice()"
                [ngClass]="{'highlight-changes': isModified('sale_price')}" />
            </div>
            <div *ngIf="f['sale_price'].invalid" class="cross-validation-error-message">
              <div *ngIf="
                  f['sale_price'].errors && f['sale_price'].errors['required'] && isSubmitted
                " class="required-note text-danger">
                Sale price is required.
              </div>
              <div *ngIf="
                  f['sale_price'].errors &&
                  f['sale_price'].errors['salePriceInvalid'] &&
                  isSubmitted
                " class="required-note text-danger">
                Sale price must be lower then regular price.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>VAT</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency" style="padding-left: 12px; padding-right: 4px">%</span>
              <input type="text" class="form-control" [(ngModel)]="taxPercentage"
                [ngModelOptions]="{ standalone: true }" [readOnly]="true" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="left-title">
            <label>Total Price</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input type="text" gfdNumbersOnly class="form-control" [maxAllowedValue]="
                  maxAllowedTotal(packageData.controls['regular_price'].value)
                " [(ngModel)]="totalAmount" [ngModelOptions]="{ standalone: true }" (blur)="calculateSaleAmount()"
                [disabled]="(reviewStatus === 2 || reviewStatus === 3) ? true : null" />
            </div>
          </div>
        </div>

        <!-- cancellation -->
        <div class="form-group">
          <div class="left-title">
            <label>
              Is Eligible for Cancellation?

              <div class="cst-tooltip-container custom-top ml-2" (mouseover)="showTooltip = true"
                (mouseout)="showTooltip = false">

                <img class="info-icon" src="assets/images/i-info-icon.svg" alt="i-info-tooltip" />
                <div class="cst-tooltip cst-tooltip-right" *ngIf="showTooltip">
                  Eligible hours to request cancellation after order placed.
                </div>
              </div>
            </label>
          </div>
          <div class="right-field">

            <div class="package-cancel-row">
              <input type="checkbox" [(ngModel)]="isEligibleForCancellation" (change)="toggleCancellationEligibility()"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="(reviewStatus === 2 || reviewStatus === 3) ? true : null" />


              <div class="currency-input-group ml-3 reserved-space"
                [class.visibility-hidden]="!isEligibleForCancellation">
                <span class="price-currency full-line mr-0">Cancel within</span>
                <input type="text" class="form-control" placeholder="Enter Hours" formControlName="cancellationHours"
                  (input)="checkValidHours($event)"
                  [ngClass]="{'highlight-changes': isModified('cancellation_time')}" />
              </div>
            </div>
            <div *ngIf="
              isEligibleForCancellation &&
              f['cancellationHours'].errors?.required &&
              isSubmitted" class="required-note text-danger">
              Cancellation hours are required.
            </div>

            <div *ngIf="
              f['cancellationHours'].errors &&
              !f['cancellationHours'].errors['required'] &&
              f['cancellationHours'].errors['min'] &&
              isSubmitted
            " class="required-note text-danger">
              Hours must be greater than 0.
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="left-title">
            <label>Description<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <div [ngClass]="{'highlight-angular-editor': isModified('description')}">
              <angular-editor *ngIf="!isReview && reviewStatus != 2 && reviewStatus != 3" formControlName="description"
                [config]="config" placeholder="Enter description here">
              </angular-editor>

              <div *ngIf="reviewStatus == 2 || reviewStatus == 3" class="custom-disable-editor"
                [innerHTML]="f['description'].value"></div>
            </div>

            <div class="right-field">
              <div *ngIf="
                  f['description'].errors &&
                  f['description'].errors['required'] &&
                  isSubmitted
                " class="required-note text-danger">
                Description is required.
              </div>
            </div>
            <div *ngIf="isReview" class="custom-disable-editor" [innerHTML]="f['description'].value"></div>
          </div>
        </div>
        <div class="form-group" *ngIf="!defaultPackage">
          <div class="left-title">
            <label>SKU<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input type="text" class="form-control" formControlName="sku" />
            <div *ngIf="
                f['sku'].errors && f['sku'].errors['required'] && isSubmitted
              " class="required-note text-danger">
              SKU is required.
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Available Qty<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input type="text" class="form-control" gfdNumbersOnly [allowDecimals]="false" formControlName="stock" />
            <div *ngIf="
                f['stock'].errors &&
                f['stock'].errors['required'] &&
                isSubmitted
              " class="required-note text-danger">
              stock is required.
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Delivery Time (days)</label>
          </div>
          <div class="right-field">
            <input type="text" class="form-control" placeholder="7 Days" formControlName="deliveryTime" gfdNumbersOnly
              [maxlength]="10" />
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Revisions</label>
          </div>
          <div class="right-field">
            <input type="text" class="form-control" placeholder="4" formControlName="revisions" gfdNumbersOnly
              [maxlength]="10" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-0" *ngIf="!isReview">
      <div class="bottom-btnrow">
        <button type="button" class="btn t-grey-btn-outline" (click)="reset()" [disabled]="reviewStatus == 2">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button type="button" class="btn t-primary-btn" (click)="save()" [disabled]="reviewStatus == 2" [disabled]="!isFormChanged">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>