<div class="modal-header">
  <h4 class="modal-title">{{ heading }}</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" #cancelModal>
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="commit-modal-content">
    <div class="form-group" [formGroup]="reasonForm">
      <h4 class="mb-1">
        {{
        isCustomReason
        ? 'Cite custom reason '
        : 'Cite reason for modification'
        }}
        <span class="required">*</span>
      </h4>
      <div class="commit-list-control" *ngIf="!isCustom">
        <!-- <ng-select2 #reasonDropdown *ngIf="reasonList.length > 0" class="select2-control" name="" id="reasonID11"
          [data]="reasonList" (valueChanged)="changeReason($event)" formControlName="reason"
          [placeholder]="'Select from list'">
        </ng-select2> -->
        <ng-select2 #reasonDropdown class="select2-control" [data]="reasonList" (valueChanged)="changeReason($event)"
          formControlName="reason" [placeholder]="'Select from list'" [hidden]="reasonList.length === 0">
        </ng-select2>

      </div>
      <div *ngIf="!isCustom && f['reason'].invalid && f['reason'].touched" class="cross-validation-error-message">
        <div *ngIf="f['reason'].errors && f['reason'].errors['required']" class="required-note text-danger">
          Reason is required.
        </div>
      </div>
      <div class="field-with-action ng-star-inserted" *ngIf="isCustom">
        <div class="tags-control" style="width: 100%">
          <input type="text" placeholder="Enter reason" formControlName="customReason"
            class="form-control ng-pristine ng-valid ng-touched" />
        </div>
        <button type="button" (click)="resetCustomReason()" class="btn btn-danger ml-2">
          <img src="assets/images/trash-icon.svg" alt="trash" />
        </button>
      </div>
      <div *ngIf="
          isSubmitted &&
          f['customReason'].errors &&
          f['customReason'].errors['pattern']
        " class="required-note text-danger">
        code is not valid.
      </div>
    </div>
  </div>
  <div class="bottom-btnrow justify-content-start">
    <button type="button" class="btn t-primary-light text-capitalize" (click)="cancel()">
      Cancel
    </button>
    <button type="button" class="btn t-primary-btn text-capitalize" [disabled]="
        (reasonForm.invalid && !isCustom) ||
        (!reasonForm.value.customReason && isCustomReason)
      " (click)="save()">
      Submit
    </button>
  </div>
</div>