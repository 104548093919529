export class CoreApiUrls {
  public static readonly adminUrls = {
    list: `admin/all`,
    create: `admin/add`,
    detail: `admin/details`,
    update: `admin/update/`,
    profileUpload: 'admin/image',
    delete: `admin/delete`,
    updateStatus: `admin/status`,
  };

  public static readonly vendorUrls = {
    list: `admin/vendor`,
    create: `admin/vendor/add`,
    detail: `admin/vendor/sellerdetails`,
    update: `admin/vendor`,
    profileUpload: 'admin/image',
    companyLogo: 'admin/vendor/companylogo',
    profileImage: 'admin/vendor/profileImage',
    tradelicense: 'admin/vendor/tradelicense',
    vatcertificate: 'admin/vendor/vatcertificate',
    delete: `admin/delete`,
    updateStatus: `admin/vendor/bulkstatusupdate`,
    autocomplete: `admin/vendor/autocomplete`,
  };

  public static readonly rolesUrls = {
    list: `admin/role/list`,
    create: `admin/role/add`,
    update: `admin/role/update/`,
    autocomplete: `admin/role/autocomplete`,
  };

  public static readonly customerUrls = {
    list: `admin/customer/list`,
    create: `admin/customer`,
    detail: `admin/customer`,
    update: `admin/customer/`,
    delete: `admin/bulkDeleteCustomer`,
    updateStatus: `admin/customer/change-status`,
  };

  public static readonly customerAddressUrls = {
    list: `admin/customer`,
    create: `admin/customer/address`,
    detail: `admin/customer`,
    update: `admin/customer/address/`,
    delete: `admin/bulkDeleteCustomer`,
    cityList: 'admin/region/citylist',
  };

  public static readonly categoriesUrls = {
    list: `admin/categories/type/list`,
    create: `admin/categories/add`,
    detail: `admin/categories/[id]`,
    update: `admin/categories/[id]`,
    bulkdelete: `admin/categories/bulkdelete`,
    bulkstatusupdate: `admin/categories/bulkstatusupdate`,
    autocomplete: `admin/categories/type/autocomplete`,
  };

  public static readonly commissionUrls = {
    list: 'admin/commission/list',
    settingDetail: 'admin/commission',
    vendorSettingDetail: 'admin/commission/seller',
    setDefault: 'admin/commission/set-default',
    updateSettingsAll: 'admin/commission/allUpdate',
    updateSetting: 'admin/commission/update',
    updateVendorSettingsAll: 'admin/commission/config/vendor',
  };

  public static readonly manageSupportUrls = {
    list: 'assets/data/support/manage-support-list.json',
  };

  public static readonly supportUrls = {
    invoiceRequestList: 'admin/invoice/requests',
  };

  public static readonly managePayoutsUrls = {
    list: 'admin/payout/list',
    detail: 'admin/payout',
    update: 'admin/payout/update-status',
  };

  public static readonly subCategoriesUrls = {
    list: `admin/subCategory/subcategory`,
    detail: `admin/subCategory/[id]`,
    create: `admin/subCategory`,
    update: `admin/subCategory/[id]`,
    bulkdelete: `admin/subCategory/bulkdelete`,
    bulkstatusupdate: `admin/subCategory/bulkstatusupdate`,
    autocomplete: `admin/subCategory/autocomplete`,
    allSubCategory: `admin/subCategory/autocomplete`,
  };

  public static readonly productUrls = {
    list: `admin/product/list`,
    detail: `admin/product`,
    create: `admin/product/add`,
    update: `admin/product`,
    bulkdelete: `admin/product/bulkdelete`,
    bulkstatusupdate: `admin/product/bulkstatusupdate`,
    imageUpload: `	admin/product/image`,
    modificationReasonList: `admin/product/getModificationReasonList`,
    commissionRate: `admin/product/getCommissionRate`,
    reviewStatusUpdate: `admin/product/update_review_status`,
  };

  public static readonly servicestUrls = {
    list: `admin/service/list`,
    detail: `admin/service`,
    create: `admin/service`,
    update: `admin/service`,
    imageUpload: `admin/service/image`,
    updateStatus: `admin/service/change-status`,
    delete: `admin/service/delete`,
    autocomplete: `admin/service/autocomplete`,
    reviewStatusUpdate: `admin/service/update_review_status`,
    projectDetail: `admin/service/projectDetails`,
  };

  public static readonly orderUrls = {
    list: `admin/orders/orderList`,
    detail: `admin/orders/viewOrder`,
  };

  public static readonly dataTransferUrls = {
    servicelist: `admin/import/autoComplete`,
    productlist: `admin/import/autoCompleteProduct`,
  };

  public static readonly teammateUrls = {
    add: `admin/teamMember`,
    updateUrl: `admin/teamMember/`,
    list: `admin/teamMember/list`,
    roletype: `admin/teamMember/roletype`,
  };

  public static readonly reviewsUrls = {
    list: `admin/review/list`,
    detail: `admin/review`,
    updateStatus: `admin/review/updatestatus`,
    delete: `admin/review`,
  };

  public static readonly couponUrls = {
    list: `admin/coupon`,
    create: `admin/coupon`,
    update: `admin/coupon`,
    delete: `admin/coupon/delete`,
    categorywisesubcategory: `admin/categories/subcategory/all`,
    categorywisesku: `admin/categories/categorywiseSkus`,
    bulkstatusupdate: `admin/coupon/change-status`,
    statusupdate: `admin/coupon/change-review`,
  };

  public static readonly bannerUrls = {
    create: `admin/banners`,
    updateUrl: `admin/banners`,
    list: `admin/banners/list`,
    details: `admin/banners`,
    updateStatus: `admin/banners/change-status`,
    productAutocomplete: `admin/product/autoComplete/crosssell`,
    skuAutocomplete: `admin/service/autoComplete`,
    bannersAutocomplete: 'admin/banners/autocomplete',
  };

  public static readonly adBlockUrls = {
    list: 'admin/blocks/list',
    create: `admin/blocks`,
    updateUrl: `admin/blocks`,
    details: `admin/blocks`,
    updateStatus: 'admin/blocks/change-status',
  };

  public static readonly adPurchasesUrls = {
    list: 'assets/data/content-management/ad-purchases.json',
    create: ``,
    updateUrl: ``,
    details: ``,
    updateStatus: '',
  };

  public static readonly settingsUrls = {
    settings: `admin/settings/`,
    updateUrl: `admin/settings/update`,
  };

  public static readonly taxRatesUrls = {
    add: `admin/taxRate`,
    update: `admin/taxRate/`,
    detail: 'admin/taxRate/',
    list: 'admin/taxRate/list',
    countryList: 'admin/region/countrylist',
    totalCount: 'admin/taxRate/totalCount',
    statusupdate: 'admin/taxRate/',
  };
  public static readonly pagesUrls = {
    list: `pages`,
    create: `pages`,
    updateStatus: `pages/bulkstatusupdate`,
    configuration: `admin/pageConfig`,
    pageDetails: 'admin/pageConfig/getSection',
    addSection: 'admin/pageConfig/addSection',
    getTandCVersionHistory: 'pages/getTermsAndCondVersionHistory'
  };
}
