import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  socket!: Socket;
  public globalSearchKey: BehaviorSubject<any> = new BehaviorSubject(false);
  public isHeaderEmailNotificationClicked: BehaviorSubject<any> = new BehaviorSubject(false);

  private unreadCountSubject = new BehaviorSubject<number>(0);
  unreadCount$ = this.unreadCountSubject.asObservable();

  // for updating menus
  private menuState = new BehaviorSubject<{
    menuID: number;
    subMenuID: number;
  }>({ menuID: 0, subMenuID: 0 });
  menuState$ = this.menuState.asObservable();

  constructor(private httpClient: HttpClient) {}

  // Login
  login(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/login`,
      payload
    );
  }

  rememberMe(emailId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/rememberme/${emailId}`
    );
  }

  checkLoginUser(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/validateUser`,
      payload
    );
  }

  validateVendor(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/validate`,
      payload
    );
  }

  getSiteSettings(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/settings/getSiteSettings`
    );
  }

  forgotpassword(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/forgotpassword`,
      payload
    );
  }

  resetpassword(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/resetpassword`,
      payload
    );
  }

  sendForgetPwdEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/forgotpassword`,
      payload
    );
  }

  checkGoogleOrFacebookUser(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/google/login`,
      payload
    );
  }

  // Register
  register(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/register`,
      payload
    );
  }

  uploadProfileImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/profile/image`,
      payload
    );
  }

  uploadLogoImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/company/logo`,
      payload
    );
  }

  uploadLicenceImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/tradelicense/image`,
      payload
    );
  }

  uploadVATCertificate(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/vatcertificate/image`,
      payload
    );
  }

  // Profile details
  getProfileDetails(vendorID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/profile/${vendorID}`
    );
  }

  // Dashboard
  getDashboardDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/${payload.vendorID}`
    );
  }

  // Check vendor code
  checkVendorCode(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/vendor/checkVendorCode?vendorCode=${payload}`
    );
  }

  getHomeCategories(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/category`);
  }

  // notifcations
  getAllNotifications(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/getNotification/${vendorID}?${payload}`
    );
  }

  getHeaderNotifications(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/getNotification?${payload}`
    );
  }

  getInvoiceNotifications(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/invoiceRequestsNotification/${vendorID}`
    );
  }

  socketConnection(id: string): void {
    this.socket = io(environment.backendDomain, {
      auth: {
        id,
      },
      transports: ['websocket', 'polling'], // Ensure all transports are allowed
    });
    // console.log('from socketConnection')
    // console.log(this.socket)
  }

  socketDisconnect(): void {
    if (this.socket?.connected) {
      this.socket.disconnect();
    }
  }

  markAsReadNotification(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/updateNotification`,
      payload
    );
  }

  listenEvent(eventName: string): any {
    return new Observable((subscribe) => {
      this.socket?.on(eventName, (data: any) => {
        subscribe.next(data);
        console.log('listenEvent:', data);
      });
    });
  }

  getGeneralConfig(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/generalConfig`
    );
  }

  // Contact Us
  contactus(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}contactus`,
      payload
    );
  }

  // Verift OTP
  sendOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/sendOtp`,
      payload
    );
  }

  reSendOTP(emailId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/resendOtp/${emailId}`
    );
  }

  verifyOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/otpvalidation`,
      payload
    );
  }

  setGlobalSearchKey(key: string): void {
    this.globalSearchKey.next(key);
  }

  getGlobalSearchKey(): Observable<any> {
    return this.globalSearchKey;
  }

  setHeaderEmailNotification(key: any): void {
    this.isHeaderEmailNotificationClicked.next(key);
  }

  getHeaderEmailNotification(): Observable<any> {
    return this.isHeaderEmailNotificationClicked;
  }

  updateDeactivationStatus(sellerID: string, payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/deactivation/updateDeactivationStatus/${sellerID}`,
      payload
    );
  }

  updateReactivationStatus(sellerID: string, payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/deactivation/updateReactivationStatus/${sellerID}`,
      payload
    );
  }

  // Get activeOrderList
  getActiveOrderList(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/orders/activeOrderlist/${vendorID}?${payload}`
    );
  }

  setUnreadCount(count: number): void {
    this.unreadCountSubject.next(count);
  }

  updateMenuState(menuID: number, subMenuID: number): void {
    this.menuState.next({ menuID, subMenuID });
  }
}