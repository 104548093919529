import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import {
  StorageService,
  GeneralService,
  ValidationService,
} from '@app/core/services';
import { SupportService } from '@app/core/services/support.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';
import { VendorService } from '@app/core/services';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take, takeUntil, timer } from 'rxjs';

import { getErrorReason, isValidFile } from './file-upload-util';
@Component({
  selector: 'app-ticket-chat-configuration-modal',
  templateUrl: './ticket-chat-configuration-modal.component.html',
  styleUrls: ['./ticket-chat-configuration-modal.component.scss'],
})
export class TicketChatConfigurationModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chatModalObject: any;
  @Output() imgUploaded = new EventEmitter();
  @ViewChild('replyDiv') replyDiv: ElementRef;
  msgList: any[] = [];
  vendorId = this.storageService.getCookie('userID');

  config!: AngularEditorConfig;
  payload: any = {
    to_user: '',
    from_vendor: this.vendorId,
    from_user: '',
    to_vendor: '',
    from_admin: '',
    to_admin: '',
    title: '',
    message: '',
  };
  allowedFileExt = 'png|.jpeg|.jpg|.gif|.doc|.docx|.txt|.zip|.csv|.pdf';
  maxFileSize = 1024 * 1024 * 5;
  fromChatList: any = [];
  toChatList: any = [];
  ticketFormData: FormData = new FormData();
  ticketToUpload: any[] = [];
  ticketToSend: any;
  ticketImageUrl: any;
  ticketeUploadInput: any;
  // selectedImageCount: number = 0;
  selectedFileCount = 0;
  isVendor = true;
  isOpen = 'open';
  status: any;
  vendorID = this.storageService.getCookie('userID');

  ticketId: string;

  replyData = new UntypedFormGroup({
    msg: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    private storageService: StorageService,
    private supportService: SupportService,
    public generalService: GeneralService,
    private vendorService: VendorService
  ) {
    super();
  }

  get f() {
    return this.replyData.controls;
  }

  ngOnInit(): void {
    this.socketInit();
    this.getNewMessage();
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
      height: '10rem',
      sanitize: true,
      defaultFontName: '',
      toolbarHiddenButtons: [
        [
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'fontName',
          'insertUnorderedList',
          'insertOrderedList',
          'heading',
          'link',
          'unlink',  
        ],
        [
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'customClasses',
          'fontSize',
          'textColor',
          'backgroundColor',
          'toggleEditorMode',
        ],
      ],
    };
    this.supportService
      .readMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            console.log('Message read successfully');
            // Extract the unread_tickets_count from the response
            // console.log('res?.data?.unread_tickets_count')
            // console.log(res?.data?.unread_tickets_count)
            const unreadCount = res?.data?.unread_tickets_count || 0;
            this.vendorService.setUnreadCount(unreadCount); // Update the unread count in the service

            // Update the readMessage status in the shared service
            this.supportService.updateReadMessageStatus(true);
          } else {
            // Handle failure scenario
            this.supportService.updateReadMessageStatus(false);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chatModalObject'] && changes['chatModalObject'].currentValue) {
      this.chatModalObject = changes['chatModalObject'].currentValue;
      this.payload.ticket_id = this.chatModalObject?._id || '';
      this.payload.to_admin = this.chatModalObject?.admin || '';
      this.isVendor = this.chatModalObject?.isVendor;
      this.isOpen = this.chatModalObject?.isOpen;
      this.status = this.chatModalObject?.status;
      this.ticketId = this.chatModalObject._id;

      console.log('this.chatModalObject')
      console.log(this.chatModalObject)

      this.getMessages();
    }
  }

  ngAfterViewInit() {
    if (this.replyDiv) {
      timer(150)
        .pipe(take(1))
        .subscribe(() => {
          this.replyDiv.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        });
    }
  }

  
  ngOnDestroy(): void {
    this.vendorService.socketDisconnect();
    this.markMessagesAsRead();
    // Unsubscribe all observables
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  /*
  async ngOnDestroy(): Promise<void> {
    this.vendorService.socketDisconnect();
  
    try {
      // Wait for messages to be marked as read
      await this.markMessagesAsRead();
      console.log('Messages marked as read before closing the modal.');
    } catch (err) {
      console.error('Error marking messages as read:', err);
    }
  
    // Cleanup subscriptions
    this.destroyed$.next();
    this.destroyed$.complete();
  }
    */
/*
  markMessagesAsRead(): void {
    this.supportService.readMessages(this.chatModalObject._id)
      .pipe(take(1)) // This will automatically unsubscribe after the first emission
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            console.log('Messages marked as read successfully');
            const unreadCount = res?.data?.unread_tickets_count || 0;
            this.vendorService.setUnreadCount(unreadCount); // Update the unread count
          }
        },
        error: (err) => {
          console.log('Error marking messages as read', err);
        }
      });
  }
  */
  markMessagesAsRead(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.supportService.readMessages(this.chatModalObject._id)
        .pipe(take(1)) // Automatically unsubscribes after the first emission
        .subscribe({
          next: (res) => {
            if (res && res?.data) {
              console.log('Messages marked as read successfully');
              const unreadCount = res.data.unread_tickets_count || 0;
              this.vendorService.setUnreadCount(unreadCount); // Update unread count

              // emit msg read
              this.supportService.emitMarkMessagesAsReadComplete();  // Emit event after completion

            }
            resolve(); // Resolve the promise once done
          },
          error: (err) => {
            console.error('Error marking messages as read', err);
            reject(err); // Reject the promise in case of error
          },
        });
    });
  }
   

  getNewMessage(): void {
    this.getMessages()
    console.log('in getNewMessage')
    this.vendorService
    .listenEvent('adminTicketNotification')
    .subscribe(
      (messageData: any) => {
        console.log('Socket triggered:', messageData);
        if (messageData.ticket_id === this.ticketId) {
          console.log('Fetching messages for ticket:', this.ticketId);
          this.getMessages(); 
        }
    },
    (error) => {
      console.error('Error listening to adminNotificationData:', error);
    }
  );
  }


  getMessages(): void {
    this.ticketId = this.chatModalObject._id;
    this.supportService
      .getMessages(this.chatModalObject._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res && res?.data) {
            // this.msgList = res?.data || [];
            this.msgList = res.data.map((message: any) => {
              // Process the files
              if (message.ticket_files) {
                message.files = message.ticket_files.map((fileUrl: string) => {
                  const fileType = fileUrl.split('.').pop()?.toLowerCase();
                  const fileName = fileUrl.split('/').pop() || '';
                  return {
                    file_name: fileName,
                    file_type: fileType,
                    img_url: fileUrl
                  };
                });
              } else {
                message.files = [];
              }
              return message;
            });
          }
          console.log('this.msgList', this.msgList);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  sendMessage(): void {
    if (this.replyData.invalid) {
      this.replyData.markAllAsTouched();
      return;
    }

    // function to  remove leading & trailing spaces and empty para
    const sanitizedMessage = this.sanitizeMessage(this.replyData.get('msg').value)
    // let messageContent = this.replyData.get('msg').value;

    const ticketId = this.chatModalObject?._id;

    if (this.ticketFormData.getAll('ticketfiles').length) {
      this.ticketFormData.append('message', sanitizedMessage);
      this.ticketFormData.append('ticketId', ticketId);

      if(this.isVendor) {
        this.supportService
        .sendVendorTicketFile(this.ticketFormData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.getMessages();
            this.resetForm();
          },
          error: () => {},
        });
      } else {
        this.supportService
        .sendTicketFile(this.ticketFormData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.getMessages();
            this.resetForm();
          },
          error: () => {},
        });
      }
      
    } else {
      const payload = {
        message: sanitizedMessage,
      };
      this.supportService
        .sendMessage(payload, ticketId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.getMessages();
            this.resetForm();
          },
          error: () => {},
        });
    }
  }

  resetForm(): void {
    this.replyData.reset();
    this.selectedFileCount = 0;
    this.ticketFormData = new FormData();
  }

  handleFileInput(event: any, id: string): void {
    this.ticketFormData = new FormData();
    this.ticketId = id;
    const files = event.target.files;
    this.selectedFileCount = 0;
    if (files && files.length > 0) {
      if (files.length <= 10) {
        for (const file of files) {
          const fileDetail = isValidFile(
            file,
            this.maxFileSize,
            this.allowedFileExt,
            ''
          );
          if (fileDetail.validity) {
            this.selectedFileCount = this.selectedFileCount + 1;
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToUpload.push(file);
          } else {
            const reason = getErrorReason(fileDetail.fileData);
            this.generalService.displayError(reason);
          }
        }
        this.processFiles();
      } else {
        this.generalService.displayError('Maximum 10 files are allowed');
      }
    } else {
      this.generalService.displayError('Please select files');
    }
  }

  processFiles(): void {
    this.ticketToUpload.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.ticketImageUrl = event.target.result;
        if (this.ticketeUploadInput) {
          this.ticketeUploadInput.nativeElement.value = '';
        }
      };
      reader.readAsDataURL(file);
    });
  }

  uploadTicketFile(event: any, ticketID: any): void {
    const file: File = event.target.files[0];
    this.generalService
      .confirmationDialog('Are You Sure?', `You want to send this file!`)
      .then((result: any) => {
        if (result.isConfirmed) {
          if (file) {
            this.ticketFormData = new FormData();
            this.ticketFormData.append('ticketfiles', file);
            this.ticketToSend = event.target.files[0];
            if (this.ticketToSend) {
              this.ticketFormData.append('ticket_id', ticketID);
              this.supportService
                .uploadTicketFile(this.ticketFormData)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: (res) => {
                    if (res) {
                      this.imgUploaded.emit();
                      this.generalService.displaySuccess(res?.message);
                      this.getMessages();
                      this.resetForm();
                    }
                  },
                  error: (error) => {
                    this.generalService.displayError(error?.error?.message);
                  },
                });
            }
          } else {
            this.ticketeUploadInput.nativeElement.value = '';
            this.generalService.displayError('Please select file');
          }
        }
      });
  }

  socketInit(): void {
    if (this.vendorID) {
      this.vendorService.socketConnection(this.vendorID);
    }
  }

  get getUploadedFileName() {
    const allFiles = this.ticketFormData.getAll('ticketfiles');
    const name = allFiles.map((item) => item['name']).join(',\n');
    return name;
  }

  sanitizeMessage(message: string) {
    message = message.replace(/&nbsp;/g, '').replace(/&#160;/g, ''); // Remove all &nbsp; (non-breaking spaces) and &#160; (HTML encoded non-breaking space)
    message = message.replace(/^(<br\s*\/?>)+|(<br\s*\/?>)+$/g, ''); // Remove leading and trailing <br> or <br/> tags 
    message = message.replace(/<p><br><\/p>/g, ''); // Remove multiple <p><br></p> tags or any empty paragraphs
    message = message.replace(/(<p><\/p>)+/g, '<p></p>'); // Remove multiple consecutive empty paragraphs and limit empty space to one
    message = message.replace(/^(<p><\/p>)+|(<p><\/p>)+$/g, ''); // Remove leading and trailing empty <p></p> tags
    message = message.trim(); // Trim the result to remove any remaining leading/trailing spaces
    return message;
  }
}
