import { Component, OnInit } from '@angular/core';

import { GeneralService } from '../../../core/services/general.service';

import { StorageService } from '../../../core/services/storage.service';
import { VendorService } from '../../../core/services/vendor.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
})
export class AllNotificationsComponent implements OnInit {
  vendorID = this.storageService.getCookie('userID');
  isAll = false;
  filterID = 0;
  notificationsLists: any = [];
  allCount: any = 0;
  unreadCount: any = 0;
  requestCount: any = 0;
  invoiceNotifications: any = [];

  constructor(
    private storageService: StorageService,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private router: Router,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        this.filterID = params.type === 'all' ? 0 : 2;
      }
    });

    this.getNotifications();
  }

  getNotifications(): void {
    let payload: any = {
      filter: this.filterID,
    };

    this.vendorService
      .getHeaderNotifications(
        this.vendorID,
        this.generalService.createParamsURL(payload)
      )
      .subscribe(
        (res) => {
          this.notificationsLists = res.data;
          this.allCount = res?.all_count;
          this.unreadCount = res?.unread_count;
          this.requestCount = res?.request_count;
        },
        (err) => {
          this.generalService.displayError(err.error.message);
        }
      );
  }

  getInvoiceNotifications(): void {
    return;
    this.vendorService.getInvoiceNotifications(this.vendorID).subscribe(
      (res) => {
        const invoiceNotifications =
          res?.data?.done?.length > 0 ? res?.data?.done : [];
        const invoiceNotificationsTodos =
          res?.data?.todo?.length > 0 ? res?.data?.todo : [];
        this.invoiceNotifications = [
          ...invoiceNotifications,
          ...invoiceNotificationsTodos,
        ];
        this.requestCount = invoiceNotificationsTodos?.length;
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  getAllNotifications(): void {
    this.isAll = true;
    this.getNotifications();
  }

  markAllAsRead(): void {
    const payload = {
      is_all: true,
      adminId: this.vendorID,
    };
    this.vendorService.markAsReadNotification(payload).subscribe(
      (res) => {
        // this.getInvoiceNotifications();
        this.getNotifications();
        this.generalService.displaySuccess(res.message);
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  filterApply(type: any): void {
    this.filterID = type;
    this.getNotifications();
  }

  markAsReadNotification(id: any): void {
    const payload = {
      notification_id: id,
      is_all: false,
      adminId: this.vendorID,
    };
    this.vendorService.markAsReadNotification(payload).subscribe(
      (res) => {
        this.getInvoiceNotifications();
        this.getNotifications();
      },
      (err) => {
        this.generalService.displayError(err.error.message);
      }
    );
  }

  redirectToPage(page: string, id: string): void {
    switch (page) {
      case 'admin_user_mgt':
        this.router.navigate(['/home/user-management/admin']);
        break;
      case 'vendor_user_mgt':
        this.router.navigate([`/home/user-management/view-vendor/${id}`]);
        break;
      case 'vendor_support':
        // this.router.navigate(['/home/support/vendor-tickets']);
        this.router.navigate(['/home/support/vendor-tickets'], {
          queryParams: { ticketId: id },
        });
        break;
      case 'customer_support':
        this.router.navigate(['/home/support/customer-tickets'], {
          queryParams: {ticketId: id}
        });
        break;
      case 'reviews':
        this.router.navigate(['/home/review/review-details'], {
          queryParams: {rid: id}
        });
        break;
      case 'services':
        this.router.navigate(['/home/service/add-service'], {
          queryParams: {sid: id}
        });
        break;
      case 'orders':
        this.router.navigate(['/home/service/order-details'], {
          queryParams: {oid: id}
        });
        break;
      default:
        console.warn('No redirect page specified');
    }
  }
}
