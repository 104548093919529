export const environment = {
  production: false,
  // baseApiUrl: 'https://gfdapi-staging.mplatformtech.com/v2/',
  backendDomain: 'https://gfdapi-dev.mplatformtech.com',
  baseApiUrl: 'https://gfdapi-dev.mplatformtech.com/v2/',
  // backendDomain: 'http://localhost:7500',
  // baseApiUrl: 'http://localhost:7500/v2/',
  googleKey: 'AIzaSyBBoylO2TyrZGngh00NHXCG6pwr46f4L7Q',
  googleMapUrl:
    'https://maps.googleapis.com/maps/api/js?v=weekly&key=${key}&libraries=visualization,places,drawing,marker,geometry&v=weekly&callback=Function.prototype',
};