import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GeneralService, ValidationService } from '@app/core/services';
import { CatalogManagementService } from '@app/core/services/catalog-management.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-commit-changes-modal',
  templateUrl: './commit-changes-modal.component.html',
})
export class CommitChangesModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @Input() heading = 'Commit changes';
  @Input() isCustomReason = false;
  @Input() errorMessage = 'Custom Reason is required.';
  @ViewChild('cancelModal') cancelModal: any;
  @ViewChild('reasonDropdown') reasonDropdown!: ElementRef;

  @Output() modificationReason = new EventEmitter();
  isCustom = false;
  reasonForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', ValidationService.required),
    customReason: new UntypedFormControl(
      '',
      Validators.pattern(/^\S(?:\s*\S)*$/)
    ),
  });

  reasonList: any = [];
  isSubmitted = false;
  constructor(
    private catalogMngtService: CatalogManagementService,
    private generalService: GeneralService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isCustom = this.isCustomReason;
    this.getModificationReason();

    // setTimeout(() => {
    //   if (this.reasonDropdown?.nativeElement) {
    //     $(this.reasonDropdown.nativeElement).select2();
    //   }
    // }, 300);
  }

  getModificationReason(): void {
    if (!this.isCustomReason) {
      this.catalogMngtService
        .getModificationReason()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response) => {
            if (response) {
              this.reasonList = response?.data;
              this.reasonList?.push({
                id: 'custom',
                text: '+ Custom Reason',
              });

              // // Delay initialization for proper rendering
              // setTimeout(() => {
              //   if (this.reasonDropdown?.nativeElement) {
              //     $(this.reasonDropdown.nativeElement).select2();
              //   }
              // }, 300);
            }
          },
          error: (error) => {
            this.generalService.displayError(error?.error?.message);
          },
        });
    }
  }

  save(): void {
    this.isSubmitted = true;
    if (this.isCustomReason || this.isCustom) {
      if (this.reasonForm?.value?.customReason.trim()) {
        this.modificationReason?.emit(
          this.reasonForm?.value?.customReason.trim()
        );
        this.cancel();
      } else {
        this.reasonForm.markAllAsTouched();
      }
    } else {
      if (this.reasonForm.invalid) {
        this.reasonForm.markAllAsTouched();
      } else {
        this.modificationReason?.emit(this.reasonForm?.value?.reason);
        this.cancel();
      }
    }
  }

  cancel(): void {
    this.reasonForm.reset();
    this.cancelModal?.nativeElement?.click();
    this.isCustom = this.isCustomReason;
    this.isSubmitted = false;
    // this.reasonList = [];

    // setTimeout(() => {
    //   if (this.reasonDropdown?.nativeElement) {
    //     $(this.reasonDropdown.nativeElement).select2();
    //   }
    // }, 300);
  }

  get f() {
    return this.reasonForm.controls;
  }

  changeReason(data: any): void {
    if (data === 'custom') {
      this.isCustom = true;
    }
  }

  resetCustomReason(): void {
    this.reasonForm.patchValue({
      customReason: '',
      reason: '',
    });
    this.isCustom = this.isCustomReason;
  }
}
