import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CoreApiUrls } from '../api-constant';
import { IVendorData } from '@app/shared/model/vendor.model';
import {
  IApiResponse,
  IListApiResponse,
} from '@app/shared/model/api-response.model';
import { IReviewUpdate } from '@app/shared/model/product.model';
import { ICategory } from '@app/shared/model/categories.model';
import { IServices } from '@app/shared/model/services.model';

@Injectable({
  providedIn: 'root',
})
export class CatalogManagementService {
  constructor(private httpClient: HttpClient) {}

  // Categories
  getAllCategories(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/categories/type/list?type=0`
    );
  }

  getCategories(type: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.categoriesUrls.autocomplete}?type=${type}`
    );
  }

  getAllSubCategories(type: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.subCategoriesUrls.allSubCategory}?type=${type}&returnType=subcategory`
    );
  }

  getSubCategories(categoryId: string): Observable<IApiResponse<ICategory[]>> {
    return this.httpClient.get<IApiResponse<ICategory[]>>(
      `${environment.baseApiUrl}${CoreApiUrls.subCategoriesUrls.list}/${categoryId}`
    );
  }

  getCategorySubcategory(type?: number, status: number = 1): Observable<any> {
    if (type === 0 || type === 1) {
      return this.httpClient.get<any>(
        `${environment.baseApiUrl}${CoreApiUrls.subCategoriesUrls.autocomplete}?type=${type}&status${status}&returnType=subcategory`
      );
    } else {
      return this.httpClient.get<any>(
        `${environment.baseApiUrl}${CoreApiUrls.subCategoriesUrls.autocomplete}?status${status}`
      );
    }
  }

  getVendorAutocomplete(
    status: number = 1
  ): Observable<IApiResponse<IVendorData[]>> {
    return this.httpClient.get<IApiResponse<IVendorData[]>>(
      `${environment.baseApiUrl}${CoreApiUrls.vendorUrls.autocomplete}?status=${status}`
    );
  }

  getCommissionRate(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.commissionRate}/${payload.vendorID}`,
      payload
    );
  }

  // Services
  getServices(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.autocomplete}?sellerId=${vendorID}`
    );
  }

  getServiceList(payload: string): Observable<IListApiResponse<IServices>> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.list}?${payload}`
    );
  }

  enableDisableServices(payload: {
    status: number;
    service_ids: string[];
  }): Observable<IApiResponse> {
    return this.httpClient.post<IApiResponse>(
      `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.updateStatus}`,
      payload
    );
  }

  bulkDeleteServices(payload: {
    service_ids: string[];
  }): Observable<IApiResponse> {
    return this.httpClient.post<IApiResponse>(
      `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.delete}`,
      payload
    );
  }

  getServiceDetails(serviceID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/service/${serviceID}`
    );
  }

  setService(payload: any): Observable<any> {
    if (payload.serviceID) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.update}/${payload.serviceID}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.create}`,
        payload
      );
    }
  }

  uploadServiceImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/service/image`,
      payload
    );
  }

  getInputTypes(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}admin/inputtype`);
  }

  // Products
  getAllProducts(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/product/autoComplete?sellerId=${vendorID}`
    );
  }

  getProducts(payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.list}?${payload}`
    );
  }

  enableDisableProducts(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.bulkstatusupdate}`,
      payload
    );
  }

  bulkDeleteProducts(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.bulkdelete}`,
      payload
    );
  }

  setProduct(payload: any): Observable<any> {
    if (payload.productID) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}${CoreApiUrls.productUrls.update}/${payload.productID}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}${CoreApiUrls.productUrls.create}`,
        payload
      );
    }
  }

  getProductDetails(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.detail}/${id}`
    );
  }

  uploadProductImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.imageUpload}`,
      payload
    );
  }

  // Sellers
  getAllSellers(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/allsellers`
    );
  }

  // Return-request
  getReturnRequestData(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      // `${environment.baseApiUrl}admin/returnItemRequest/${vendorID}?${payload}`
      `${environment.baseApiUrl}admin/returnItems?${payload}`
    );
  }

  statusChangeReturnRequest(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulketurnItemStatusUpdate`,
      payload
    );
  }

  getReturnRequestDetail(vendorID: string, requestID: string): Observable<any> {
    return this.httpClient.get<any>(
      // `${environment.baseApiUrl}admin/returnItemDetails/${requestID}/${vendorID}`
      `${environment.baseApiUrl}admin/returnItems/${requestID}`
    );
  }

  crteateCreditMemo(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/return/createCreditNote`,
      payload
    );
  }

  productWiseStatusChangeReturnRequest(
    payload: any,
    requestID: string,
    productID: string
  ): Observable<any> {
    return this.httpClient.put<any>(
      // `${environment.baseApiUrl}admin/updateReturnItemStatus/${requestID}`,
      `${environment.baseApiUrl}admin/return/returnStatus/${requestID}`,
      payload
    );
  }
  bulkDeleteReturnItem(payload: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}admin/bulkDeleteProductReturn`,
      payload
    );
  }

  // Cross sell Up-Sell
  getCrossSellUpSellDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/product/crossSellUpSell?seller_id=${payload.vendorID}&type=${payload.type}`
    );
  }

  setCrossSellUpSell(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/product/crossSellUpSell`,
      payload
    );
  }

  // Modification Reason
  getModificationReason(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}${CoreApiUrls.productUrls.modificationReasonList}`
    );
  }

  updateModificationStatus(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/service/updateModificationRequest`,
      payload
    );
  }

  getReasons(userType: any, reasonType: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/settings/getReasons?userType=${userType}&reasonType=${reasonType}`
    );
  }

  /**
   * aprrove or reject , review status of service or product
   * @param payload
   * @param type
   * @returns
   */
  updateReviewStatus(payload: IReviewUpdate, type: number): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}${
        type === 0
          ? CoreApiUrls.servicestUrls.reviewStatusUpdate
          : CoreApiUrls.productUrls.reviewStatusUpdate
      }`,
      payload
    );
  }
}
